@import url("https://fonts.googleapis.com/css2?family=Righteous&display=swap");
.load-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000;
  background-image: url(../images/clouds.jpg);
  background-size: cover;
  background-position: center;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  .load-screen-logo {
    display: flex;
    justify-content: space-around;
    img {
      height: 25vh;
    }
  }
  .main-text {
    font-family: "Righteous", cursive;
    font-size: 7rem;
  }
  .progress-bar {
    width: 100%;
    height: 20px;
    background-color: #fff;
    margin-top: 2rem;
    .progress-bar-inner {
      height: 100%;
      background-color: #000;
      animation: loading 4s forwards;
    }
  }
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
