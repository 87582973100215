@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;400;700&family=VT323&display=swap");
html {
  font-size: 10px;
}
body {
  margin: 0;
  padding: 0;
  font-family: "VT323", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bold {
  font-weight: 700;
  letter-spacing: 1px;
}

@keyframes slideIn {
  0% {
    transform: translatey(50px);
  }
  100% {
    transform: translate(0);
  }
}
