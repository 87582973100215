.window {
  width: 200px;
  background-color: silver;
  position: absolute;
  bottom: 44px;
  left: 0;
  z-index: 999;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  border-right: 2px solid gray;
  border-bottom: 2px solid gray;
}
.window .window-header {
  display: flex;
  justify-content: space-between;
  padding: 2px 3px;
  background: #142694;
  margin: 3px;
}
.window .window-header-title {
  color: white;
  font-weight: 700;
  font-size: 1.8rem;
  vertical-align: center;
}
.window .window-body {
  text-align: center;
  font-size: 1.5rem;
}
.window .window-header-control {
  background-image: url("../images/cross.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 20px;
  width: 20px;
  padding: 2px 4px 0 4px;
  border: 2px;
  margin: 0;
  cursor: pointer;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  box-shadow: inset 1px 1px #dfdfdf, 1px 0 #000, 0 1px #000, 1px 1px #000;
}
.window .window-header-control img {
  height: 10px;
  padding: 0;
  margin: 0;
}

.window-header-controls button:active {
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}/*# sourceMappingURL=NavWindow.css.map */