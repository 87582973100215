
.applications {
  padding: 1rem 0 0 1rem;

  .application-item {
    display: flex;
    margin-bottom: 2rem;
    flex-direction: column;
    width: 70px;

    img {
      height: 60px;
      width: 60px;
      margin: 0 auto;
    }
    .application-title {
      margin: 0 auto;
      font-size: 1.5rem;
      color: white;
    }
  }
}
.application-item:hover {
  background-color: #f5f5f555;
}
