.navigation {
  height: 40px;
  background-color: silver;
  padding: 0;
  color: rgb(0, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 3px solid rgba(39, 39, 39, 0.67);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.navigation .start-button-wrapper {
  font-weight: 700;
  margin-left: 5px;
  margin-bottom: 2px;
  vertical-align: middle;
  display: inline-block;
  padding: 2px 6px 3px;
  background-color: silver;
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid gray;
  border-bottom: 1px solid gray;
  box-shadow: inset 1px 1px #dfdfdf, 1px 0 #000, 0 1px #000, 1px 1px #000;
  min-width: 59px;
  margin-right: 6px;
  color: #000;
}
.navigation .start-button-wrapper :hover {
  cursor: pointer;
}
.navigation .start-button-wrapper ::-moz-selection {
  background-color: rgba(0, 0, 0, 0);
  color: rgb(0, 0, 0);
}
.navigation .start-button-wrapper ::selection {
  background-color: rgba(0, 0, 0, 0);
  color: rgb(0, 0, 0);
}
.navigation .start-button-wrapper .start-button {
  background-repeat: no-repeat;
  width: 40px;
  padding: 0 !important;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 90px;
}
.navigation .start-button-wrapper .start-button img {
  height: 25px;
}
.navigation .start-button-wrapper .start-button span {
  font-size: 2rem;
  letter-spacing: 1px;
}
.navigation .date-wrapper {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  margin: 0 5px 0 0;
  padding: 5px 10px;
}
.navigation .date-wrapper .date {
  font-family: "MS Gothic", sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
}
.navigation .date-wrapper .date ::-moz-selection {
  background-color: #000;
  color: #fff;
}
.navigation .date-wrapper .date ::selection {
  background-color: #000;
  color: #fff;
}
.navigation .date-wrapper .date .time {
  padding: 0 3px;
}

.start-button-wrapper:active {
  border-top: 1px solid gray;
  border-left: 1px solid gray;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}/*# sourceMappingURL=Navigation.css.map */