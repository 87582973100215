@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;400;700&family=VT323&display=swap");

.home-container {
  background-color: #01807e;
  background-image: url('../images/bg.gif');
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}
